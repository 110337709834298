
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import {
    auth
} from '../firebase'
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta:{
            auth : true,
            title : 'Home'
        }
    },
    
        {
            path: '/admins-list',
            name: 'ListAdmins',
            component: () => import('./../views/Admins/List.vue'),
            meta:{
                auth : true,
                title : 'Admins List'
            }
        },
            {
            path: '/admins-list/:id',
            name: 'ViewAdmins',
            component: () => import('./../views/Admins/Edit.vue'),
            meta:{
                auth : true,
                title : 'Admins Edit'
            }
        },
        {
            path: '/clients-list',
            name: 'ListClients',
            component: () => import('./../views/Clients/List.vue'),
            meta:{
                auth : true,
                title : 'Clients List'
            }
        },
            {
            path: '/clients-list/:id',
            name: 'ViewClients',
            component: () => import('./../views/Clients/Edit.vue'),
            meta:{
                auth : true,
                title : 'Clients Edit'
            }
        },
        {
            path: '/display_text-list',
            name: 'ListDisplay_text',
            component: () => import('./../views/Display_text/List.vue'),
            meta:{
                auth : true,
                title : 'Display_text List'
            }
        },
            {
            path: '/display_text-list/:id',
            name: 'ViewDisplay_text',
            component: () => import('./../views/Display_text/Edit.vue'),
            meta:{
                auth : true,
                title : 'Display_text Edit'
            }
        },
        {
            path: '/items-list',
            name: 'ListItems',
            component: () => import('./../views/Items/List.vue'),
            meta:{
                auth : true,
                title : 'Items List'
            }
        },
        {
            path: '/add-item',
            name: 'AddItem',
            component: () => import('./../views/Items/Add.vue'),
            meta:{
                auth : true,
                title : 'Add New Item'
            }
        },
            {
            path: '/items-list/:id',
            name: 'ViewItems',
            component: () => import('./../views/Items/Edit.vue'),
            meta:{
                auth : true,
                title : 'Items Edit'
            }
        },
        {
            path: '/add-section',
            name: 'AddSection',
            component: () => import('./../views/Sections/Add.vue'),
            meta:{
                auth : true,
                title : 'Add New Section'
            }
        },
        {
            path: '/sections-list',
            name: 'ListSections',
            component: () => import('./../views/Sections/List.vue'),
            meta:{
                auth : true,
                title : 'Sections List'
            }
        },
            {
            path: '/sections-list/:id',
            name: 'ViewSections',
            component: () => import('./../views/Sections/Edit.vue'),
            meta:{
                auth : true,
                title : 'Sections Edit'
            }
        },
        {
            path: '/site_forms-list',
            name: 'ListSite_forms',
            component: () => import('./../views/Site_forms/List.vue'),
            meta:{
                auth : true,
                title : 'Site_forms List'
            }
        },
            {
            path: '/site_forms-list/:id',
            name: 'ViewSite_forms',
            component: () => import('./../views/Site_forms/Edit.vue'),
            meta:{
                auth : true,
                title : 'Site_forms Edit'
            }
        },
        {
            path: '/team_members-list',
            name: 'ListTeam_members',
            component: () => import('./../views/Team_members/List.vue'),
            meta:{
                auth : true,
                title : 'Team_members List'
            }
        },
            {
            path: '/team_members-list/:id',
            name: 'ViewTeam_members',
            component: () => import('./../views/Team_members/Edit.vue'),
            meta:{
                auth : true,
                title : 'Team_members Edit'
            }
        },
]
const router = new VueRouter({
    routes,
    mode:'history'
})

router.beforeEach((to, from, next) => {
    // Check if the route requires authentication and the user is not logged in
    document.title = to.meta.title
    auth.onAuthStateChanged(user => {
        if (to.matched.some(record => record.meta.auth) && !user) {
            next('/login')
        } else {
            next()
        }
    })
})

export default router
        