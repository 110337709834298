
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import languages from './languages.json'
import {
    auth
} from '../firebase'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        languages: languages,
        auth : {
            admin: {},
        },
        language: languages[0],
        
        admins_list: [],
        
        clients_list: [],
        
        display_text_list: [],
        
        items_list: [],
        
        sections_list: [],
        
        site_forms_list: [],
        
        team_members_list: [],
        
    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,
        
        admins_list: state => state.admins_list,
        
        clients_list: state => state.clients_list,
        
        display_text_list: state => state.display_text_list,
        
        items_list: state => state.items_list,
        
        sections_list: state => state.sections_list,
        
        site_forms_list: state => state.site_forms_list,
        
        team_members_list: state => state.team_members_list,
        
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },
        
        setAdminsList(state, admins_list) {
            state.admins_list = admins_list
        },
        
        setClientsList(state, clients_list) {
            state.clients_list = clients_list
        },
        
        setDisplayTextList(state, display_text_list) {
            state.display_text_list = display_text_list
        },
        
        setItemsList(state, items_list) {
            state.items_list = items_list
        },
        
        setSectionsList(state, sections_list) {
            state.sections_list = sections_list
        },
        
        setSiteFormsList(state, site_forms_list) {
            state.site_forms_list = site_forms_list
        },
        
        setTeamMembersList(state, team_members_list) {
            state.team_members_list = team_members_list
        },
        
    },
    actions: {
        init(context, uid) {
            const id = uid
            // const id = 'fire'
            axios.get('init.php?uid=' + id)
                .then(r => {
                    if (r.status == 200 && r.data.admin) {
                        context.state.auth.admin = r.data.admin
                        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + r.data.token
                    } else {
                        auth.signOut()
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
})
        