import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth,signInWithEmailAndPassword , sendPasswordResetEmail } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyOIqVLNKUW2CeEm1bcobbWQWfXEjbw-o",
  authDomain: "void-airport.firebaseapp.com",
  projectId: "void-airport",
  storageBucket: "void-airport.appspot.com",
  messagingSenderId: "368485735995",
  appId: "1:368485735995:web:06d613945df5ef7f05061e",
  measurementId: "G-F13HNY9DCH"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth , app , signInWithEmailAndPassword , sendPasswordResetEmail}
