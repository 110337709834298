<template>
    <div>
        <v-app-bar app height="110px" class="elevation-0">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-btn class="mx-2" icon="" to="/">
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <h3>{{title}}</h3>
            <v-spacer></v-spacer>


            <v-btn text  @click="logout">
                Logout 
                <v-spacer></v-spacer>
                <v-icon right>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app :right="$store.getters.language.rtl">
            <template v-slot:prepend>
                <v-card flat>
                    <v-card-title>
                        <img style="width:50px;border-radius: 5px;" :src="logo" alt="logo">
                        <span class="mx-2">
                            <b>
                                eluar.net
                            </b>
                            <br>
                            <small>
                                Admin Panel
                            </small>
                        </span>
                    </v-card-title>
                </v-card>
            </template>
            <v-divider class="mt-3"></v-divider>
            <v-list class="mx-2 mt-3">
                <v-list-item class="mt-1 rounded-lg" v-for="(item , index) in navigation_links" :key="index"
                    :to="item.path">
                    <v-list-item-icon>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>

                <v-list class="ma-2">
                    <v-list-item @click="changeTheme">
                        <v-list-item-icon>
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Change To {{ $vuetify.theme.dark ? 'Light' : 'Dark' }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>
            </template>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import {
        auth
    } from '../firebase'
    export default {
        data: function () {
            return {
                drawer: null,
                logo: require('./../assets/favicon.png'),
            }
        },
        computed: {
            languages() {
                return this.$store.getters.languages
            },
            auth() {
                return this.$store.getters.auth
            },
            navigation_links() {
                return [

                    // {
                    //     path : '/admins-list',
                    //     title : 'Admins',
                    // },

                    {
                        path: '/sections-list',
                        title: 'Sections',
                        icon: 'mdi-tree',
                    },
                    {
                        path: '/items-list',
                        title: 'Items',
                        icon: 'mdi-view-list',
                    },
                    {
                        path: '/clients-list',
                        title: 'Clients',
                        icon: 'mdi-account-group',
                    },
                    {
                        path: '/display_text-list',
                        title: 'Display Text',
                        icon: 'mdi-text-box',
                    },
                    {
                        path : '/site_forms-list',
                        title : 'Forms',
                        icon : 'mdi-file-document-edit-outline',
                    },

                    // {
                    //     path : '/team_members-list',
                    //     title : 'Team Members',
                    // },

                ]
            },
            title() {
                let title = 'Dashboard'
                // check if the current route is same as the route in the navigation link
                for (let i = 0; i < this.navigation_links.length; i++) {
                    if (this.navigation_links[i].path == this.$route.path) {
                        title = this.navigation_links[i].title
                    }
                }

                return title

            },
        },
        methods: {
            logout() {
                auth.signOut()
            },
            changeTheme() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
                localStorage.setItem('dark', this.$vuetify.theme.dark)
            },
        },
        mounted() {

            this.$vuetify.theme.dark = localStorage.getItem('dark') == 'true'
        },
    }
</script>