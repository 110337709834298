<template>
    <v-app>
        <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
        <APPNavbar class="no-print" v-if="isAuth && !loading"></APPNavbar>
        <v-main v-if="!loading">
            <div class="app-wrap">
                <router-view  />

            </div>
        </v-main>
    </v-app>
</template>

<script>
    import APPNavbar from './components/Navbar.vue'
    import {
        auth
    } from './firebase'
    export default {
        components: {
            APPNavbar
        },
        name: 'App',

        data: () => ({
            loading: true,
            isAuth: false,
        }),

        mounted() {
            this.$vuetify.rtl = false
            this.$vuetify.theme.dark = false
            auth.onAuthStateChanged(user => {
				if (user) {
					this.$store.dispatch('init', user.uid)

					this.isAuth = true
                    setTimeout(() => {
                        this.loading = false
                    }, 1000);
				} else {
					// this.$router.replace({
                    //     path: '/login'
                    // })
                    this.isAuth = false
                    this.loading = false
				}
			})
        },
    };
</script>
<style>
.report-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
    text-align: start;
}
.report-table th {
    padding: 8px;
    border: 1px solid #000;
    text-align: start;
    background-color: #CCCCCC;
    font-size:14px
}
.report-table td {
    padding: 8px;
    border: 1px solid #000;
    text-align: start;
    font-size:14px
}
.report-table tr {
    page-break-inside: avoid;
    page-break-after: auto;
}

/* break page */
/* .report-table tr:last-child {
    page-break-after: always;
} */


@media print {
    .no-print {
        display: none!important;
    }
    .report-table th{
        font-size: 12px;
    }
    .report-table td{
        font-size: 12px;
    }
}

* {
    font-family: 'Lexend', sans-serif;
}
</style>